import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  email: string;
  error: string;
  successMessage: string;
  uniqueToken: string;
  queueCount: number;
  errorMsg: string;
  error1: boolean;
  message: string;
  tokenNum: string;
  uniqueQueueNo: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Queuemanagement2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  joinQueueApiCallId: string = "";
  apiqueueCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      email: "",
      error: '',
      successMessage: "",
      uniqueToken: "",
      queueCount: 0,
      errorMsg: "",
      error1:false,
      message:"",
      tokenNum:"",
      uniqueQueueNo:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const responseDataMessageId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if(responseDataMessageId === this.joinQueueApiCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if(responseJson && responseJson.success){
        this.setState({
          successMessage: "Your ticket has been created and added to the queue",
          uniqueToken: responseJson.queue.unique_token,
          errorMsg:'',
          queueCount: responseJson.queue.queue_count - 1,
          email: '',  
        });
      } else if (responseJson.error === "You are already in the Queue.") {
        this.setState({
          successMessage:"",
          errorMsg: "Your ticket has been already created and added to the queue",
          uniqueToken: responseJson.queue_token,
          queueCount: responseJson.queue_count - 1, 
        });
      } else {
        this.setState({ error: 'Failed to join queue', successMessage: '' });
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.success) {
        if (apiRequestCallId === this.apiqueueCallId) {
          this.setState({message:configJSON.newticketMsg,uniqueQueueNo:configJSON.ticketNum+responseJson.queue.unique_token,tokenNum:`${Number(responseJson.queue.queue_count)-1}`+configJSON.peopleMsg})
        }
      }
      else{
        this.setState({message:configJSON.oldticketMsg,uniqueQueueNo:configJSON.ticketNum+responseJson.queue_token,tokenNum:`${Number(responseJson.queue_count)-1}`+configJSON.peopleMsg})
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text,error1:false });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  isValidEmail = (Email: string) => {
      let reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (reg.test(Email) === false) {
        this.setState({error1:true,message:"",tokenNum:"",uniqueQueueNo:""})
      } else {
        this.buttonPressedApicall()
        
      }
  };

  btnExampleProps1 = {
    onPress: () => this.isValidEmail(this.state.email),
  };

  buttonPressedApicall(){
    const header = {
      'Content-type': 'multipart/form-data'
    }
    let form = new FormData();
    form.append("queue[user_email]", this.state.email);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiqueueCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.queueAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      form
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value, error: ""})
  }

  handleSubmit = () => {
    const {email} = this.state;
    const emailRegex = /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
    
    if(!email){
      this.setState({error: 'This field cannot be empty', successMessage: '', errorMsg: ''})
    } else if(!emailRegex.test(email)){
      this.setState({ error: 'Please enter valid email' , successMessage: '', errorMsg: ''});
    } else {
      this.setState({ error: '' });
      this.handleJoinQueue()
    }
  }
  
  handleJoinQueue = () => {
    const header = {
      "Content-Type": "multipart/form-data", 
    };

    const formData = `queue[user_email]=${encodeURIComponent(this.state.email)}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.joinQueueApiCallId = requestMessage.messageId; 

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.joinQueueApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
 
  }
  // Customizable Area End
}
 