Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "queuemanagement2";
exports.labelBodyText = "queuemanagement2 Body";
exports.joinQueueApiEndPoint="bx_block_queuemanagement2/queues";
exports.btnExampleTitle = "CLICK ME";
exports.queueAPiEndPoint = "bx_block_queuemanagement2/queues";
exports.newticketMsg = "Your ticket has been created and added to the queue";
exports.oldticketMsg = "Your ticket has already been created and added to the queue";
exports.ticketNum = "Queue Token:";
exports.peopleMsg =" people are ahead of you.";
exports.errorMsg = "Please enter valid email.";
// Customizable Area End