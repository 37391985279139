import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import { getStorageData, setStorageData } from '../../../framework/src/Utilities';
import * as Yup from 'yup';
import { create } from "@github/webauthn-json";
// Customizable Area End

export const configJSON = require('./config');

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isCreated: boolean;
  credentials: any;
  isHovered: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class BiometricAuthenticationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  handleCreateAccountId: string = ""
  addFingerPrintId: string = ""
  verifyFingerPrintID: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isCreated: false,
      credentials: {},
      isHovered: false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(this.handleCreateAccountId === apiRequestCallID) {
        if(!responseJson.errors) {
          this.setState({isCreated: true})
          setStorageData("user", JSON.stringify({token: responseJson.meta.token, email: responseJson.data.attributes.email}))
        }
      }
      this.handleAddFingerPrint(from, message);
      this.handleVerifyFingerPrint(from, message);
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const token = await getStorageData('user', true)
    if(token!== null && token.token !== "") {
      this.setState({isCreated: true})
    }
    // Customizable Area End
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if(prevState.credentials !== this.state.credentials) {
      const webauthnPayload = await create({
        publicKey: await this.state.credentials
      });
      console.log('webauthnPayload', webauthnPayload)
      this.verifyFingerprint(webauthnPayload);
    }
  }

  // Customizable Area Start

  handleAddFingerPrint = (from: string, message: Message) => {
    const apiRequestCallID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if(this.addFingerPrintId === apiRequestCallID) {
      if(!responseJson.errors) {
        this.setState({credentials: responseJson})
      }
    }
  }

  handleVerifyFingerPrint = (from: string, message: Message) => {
    const apiRequestCallID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if(this.verifyFingerPrintID === apiRequestCallID) {
      if(!responseJson.errors) {
        console.log('responseJson', responseJson)
      }
      if(responseJson.errors) {
        console.log('responseJson', responseJson)
      }
    }
  }

  validationSchema = Yup.object().shape({
    name: Yup.string().matches(/^[a-zA-Z\s]*$/, "Name should be text only").required("Name is required"),
    email: Yup
      .string()
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Please enter valid Email Id")
      .required("Email is required"),
    password: Yup
      .string()
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, 
       'Valid password is required')
      .required("Password is required"),
    confirmPassword: Yup
      .string()
      .oneOf([Yup.ref('password'),null],'Password must match')
      .required("Password is required"),
  })

  handleCondition = (condition : any, truePart : any, falsePart : any) => {
    return condition ? truePart : falsePart
  }

  AddFingerPrint = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const user = await getStorageData('user', true)
    const body = {
      email: user.email
    }
    const addFinegrprintRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addFingerPrintId = addFinegrprintRequest.messageId;
    addFinegrprintRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addFingerprintEndPoint
    );
    addFinegrprintRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    addFinegrprintRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    addFinegrprintRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(addFinegrprintRequest.id, addFinegrprintRequest);
  }

  verifyFingerprint = (response: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const body = {
      credential: response
    }
    const verifyFingerprintRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyFingerPrintID = verifyFingerprintRequest.messageId;
    verifyFingerprintRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyFingerPrintEndPoint
    );
    verifyFingerprintRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    verifyFingerprintRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    verifyFingerprintRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(verifyFingerprintRequest.id, verifyFingerprintRequest);
  }

  handleSubmit = (values: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const body = {
      "data":
      {
        "type": "email_account",
        "attributes":
        {
          "email": values.email,
          "first_name": values.name,
          "password": values.password,
          "password_confirmation": values.confirmPassword
        }
      }
    }
    const createAccountRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.handleCreateAccountId = createAccountRequest.messageId;
    createAccountRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createAccountEndPoint
    );
    createAccountRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    createAccountRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    createAccountRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(createAccountRequest.id, createAccountRequest);
  }
  handleMouseOver=()=>{
    this.setState({isHovered:true})
  }
  handleMouseOut=()=>{
    this.setState({isHovered:false})
  }

  // Customizable Area End
}
