import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import {
  Box,
  TextField,
} from "@mui/material";

import { imgVisbility, imgVisbilityOff } from "./assets";

// Customizable Area End

import Queuemanagement2Controller, {
  Props,
  configJSON,
} from "./Queuemanagement2Controller";

export default class Queuemanagement2 extends Queuemanagement2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <Box sx={styles.mainWrapper}>
        <Box sx={styles.textContainer}>
          <Typography>Enter your email</Typography>
        </Box>
        <TextField 
          type="email"
          margin="normal"
          value={this.state.email}
          onChange={this.handleEmailChange}
          sx={styles.inputStyle}
          error={!!this.state.error}
          helperText={this.state.error}
          data-test='email'
          FormHelperTextProps={{ sx: styles.helperTextStyle }}
        />
        <button 
         style={styles.buttonStyle}
         onClick={this.handleSubmit}
        >
          CLICK ME
        </button>
        
        <Box sx={{marginTop: '40px'}}>
        {!this.state.error && this.state.successMessage && (
          <>
            <Typography>{this.state.successMessage}</Typography> 
            <Typography>Queue Token: {this.state.uniqueToken}</Typography>
            <Typography>{`${this.state.queueCount} people are ahead of you`}</Typography>
          </>
        )}

        {!this.state.error && this.state.errorMsg && (
          <>
            <Typography>{this.state.errorMsg}</Typography>
            <Typography>Queue Token: {this.state.uniqueToken}</Typography>
            <Typography>{`${this.state.queueCount} people are ahead of you`}</Typography>
          </>
        )}
        </Box>
      </Box>
    );
  }
}

// Customizable Area Start

const styles = {
  mainWrapper: {
    display: "flex",
    justifyContent: 'center',
    alignItems: "center",
    flexDirection: 'column',
    height: '100vh', 
    margin: 0, 
    
  },
  helperTextStyle: {
    margin: '7px 0px 0px', 
  },
  textContainer: {
    width: '350px', 
    textAlign: 'left', 
  },
  titleText: {
    fontSize: 30,
    paddingVertical: 10,
    fontWeight: "600",
  },
  bodyText: {
    fontSize: 20,
    paddingVertical: 15,
    fontWeight: "400",
  },
  inputStyle: {
    width: "350px",
  },
  buttonStyle: {
    color:'#FFFFFF',
    backgroundColor: "#A020F0",
    padding: '15px',
    border: 'none',
    borderRadius: '8px',
    width:'350px',
    marginTop: '30px'
  },
  
};


// Customizable Area End
