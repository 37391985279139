Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.formDataApiContentType = "multipart/form-data";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.deleteApiMethodType = "DELETE";
exports.putApiMethodType = "PUT";
exports.createAccountEndPoint = "account_block/accounts";
exports.addFingerprintEndPoint = "bx_block_biometric_authentication/biometric_credentials";
exports.verifyFingerPrintEndPoint = "bx_block_biometric_authentication/fingerprint_auth_verify";
// Customizable Area End