import React from "react";

// Customizable Area Start
import BiometricAuthenticationController, { Props } from "./BiometricAuthenticationController";
import { Formik, Form, Field } from 'formik';
// Customizable Area End

export default class BiometricAuthentication extends BiometricAuthenticationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div style={styles.mainWrapper}>
        {this.state.isCreated
          ? <div style={styles.formContainer}><button onClick={this.AddFingerPrint}>Add FingerPrint</button></div>
          : <div style={styles.formContainer}>
            <h1>User Registration</h1>
            <Formik
              data-test-id="formik"
              initialValues={{ name: '', email: '', password: '', confirmPassword: '' }}
              validationSchema={this.validationSchema}
              onSubmit={(values) => this.handleSubmit(values)}
            >
              {({ errors, touched }) => (
                <Form>
                  <div style={styles.formGroup}>
                    <label htmlFor="name">Name</label>
                    <Field
                      type="text"
                      name="name"
                    />
                    {this.handleCondition(errors.name, this.handleCondition(touched.name, <div style={styles.errorText}>{errors.name}</div>, ""), "")}
                  </div>
                  <div style={styles.formGroup}>
                    <label htmlFor="email">Email</label>
                    <Field
                      type="email"
                      name="email"
                    />
                    {this.handleCondition(errors.email, this.handleCondition(touched.email, <div style={styles.errorText}>{errors.email}</div>, ""), "")}
                  </div>
                  <div style={styles.formGroup}>
                    <div style={styles.passLabel}>
                      <label htmlFor="password">Password </label>
                      <div style={styles.passInfo} onMouseEnter={this.handleMouseOver} onMouseLeave={this.handleMouseOut} >
                        <p style={styles.passInfoText}>?</p>
                        {this.state.isHovered && 
                            <div style={styles.passDetail}>
                              <p style={styles.passDescription}>Your Password must contain </p>
                              <p style={styles.passDesc}>• At least one capital letter </p>
                              <p style={styles.passDesc}>• At least one lowercase letter</p>
                              <p style={styles.passDesc}>• At least one number</p>
                              <p style={styles.passDesc}>• Minimum character length is 8 characters</p>
                            </div>
                        }
                      </div>
                    </div>
                    <Field
                      type="password"
                      name="password"
                    />
                    {this.handleCondition(errors.password, this.handleCondition(touched.password, <div style={styles.errorText}>{errors.password}</div>, ""), "")}
                  </div>
                  <div style={styles.formGroup}>
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <Field
                      type="password"
                      name="confirmPassword"
                    />
                    {this.handleCondition(errors.confirmPassword, this.handleCondition(touched.confirmPassword, <div style={styles.errorText}>{errors.confirmPassword}</div>, ""), "")}
                  </div>
                  <div style={styles.submitButtonBox}>
                    <button type="submit" style={styles.submitButton} >Register</button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>}
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  mainWrapper: {
    width: "100%",
  },
  firstWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    flexDirection: "column" as "column",
  },
  loginButton: {
    backgroundColor: "blue",
    color: "white",
    fontSize: "15px",
    border: "none",
    margin: "20px",
  },
  formContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column" as "column",
  },
  formGroup: {
    display: "flex",
    flexDirection: "column" as "column",
    margin: "20px",
    width: "100%",
  },
  errorText: {
    color: "red"
  },
  submitButtonBox: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  submitButton: {
    color: "#ffffff",
    backgroundColor: "blue",
    padding: "15px",
    border: "none",
    fontWeight: 700,
    margin: "20px",
  },
  passInfo:{
    borderRadius:"50%",
    border:"1px solid black",
    height:"12px",
    width:"12px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    margin:"1px",
    cursor:"pointer",
  },
  passLabel:{
    display:"flex" as "flex",
    flexDirection:"row" as "row",
    position:"relative" as "relative"
  },
  passInfoText:{
    fontSize:"9px",
    fontWeight: 700
  },
  passDetail:{
    width:"max-content",
    zIndex:2,
    backgroundColor:"#ffffff",
    padding:"5px 15px",
    position:"absolute" as "absolute",
    borderRadius:"10px",
    border:"1px solid black",
    left:"41%",
    bottom:"30%"
  },
  passDescription:{
    fontSize:"14px",
    fontWeight: 700,
  },
  passDesc:{
    fontSize:"14px"
  }
}
// Customizable Area End
